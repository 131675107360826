import { translate } from '../helpers/translations';

export const hintWrap = (num, cur, max = true) => {
    const div = document.createElement('div');
    cur = cur ?? '';
    div.classList.add('input-tooltip', 'hint', 'active');
    
    if (max) {
        div.innerHTML = `<div class="hint-wrap hint-max">${translate('Up to')} <b>${num} ${cur}</b></div>`;
    } else {
        div.innerHTML = `<div class="hint-wrap hint-min">${translate('From')} <b>${num} ${cur}</b></div>`;
    }
    
    return div;
};